.toaster-message.primary {
  background-color: #ec407a;
}

.toaster-message.secondary {
  background-color: #747b8a;
}

/* Add additional color classes if needed */
.toaster-message.info {
  background-color: #49a3f1;
}

.toaster-message.success {
  background-color: #66bb6a;
}

.toaster-message.warning {
  background-color: #ffa726;
}
.toaster-message.error {
  background-color: #ef5350;
}

.toaster-message.light {
  background-color: #ebeff4;
}
.toaster-message.dark {
  background-color: #42424a;
}

.toaster-message-container {
  position: fixed;
  top: -100px;
  right: 20px;
  display: flex;
  justify-content: flex-end;
  z-index: 9999;
  transition: top 1s ease-in-out;
}

.toaster-message-container.visible {
  top: 10px;
}

.toaster-message {
  background-color: #333;
  color: #fff;
  padding: 4px;
  border-radius: 2px;
}

.toaster-message-content {
  font-size: 14px;
  /* Add any additional styles for the message content if needed */
}
