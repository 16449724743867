.loader {
  width: 200px;
  height: 20px;
  background-color: transparent;
  position: relative;
  margin: 0 auto;
}

.loader-bar {
  height: 100%;
  background-color: #e91e63;
  transition: width 0.5s ease-in-out;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
}

h2,
p {
  color: black;
}
