/* slider-custom.css */

/* Change color of previous arrow */
.slick-prev:before {
  color: #9103a7; /* Replace with your desired color */
}

/* Change color of next arrow */
.slick-next:before {
  color: #9103a7; /* Replace with your desired color */
}
