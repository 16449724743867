/* Audio Player Styles */
.audio-player {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: linear-gradient(to bottom, #2f3459, #151829);
  padding: 10px;
  transition: transform 0.3s ease;
  backdrop-filter: blur(10px);
}

.audio-player.sticky {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 100px;
  z-index: 9999;
  transform: translateY(0);
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-control {
  flex: 1;
  display: flex;
  align-items: flex-start;
}

.center-control {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-control-large {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  display: none; /* Hide on small screens */
}

.right-control-small {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  display: block; /* Show on small screens */
}

.control-group {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.control-group:last-child {
  margin-right: 0;
}

.seek-button,
.play-pause,
.seek-bar,
.time,
.volume-controls,
.volume-button,
.volume-slider {
  flex-shrink: 0;
}

.seek-button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 5px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.seek-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.seek-bar {
  flex-grow: 1;
  height: 4px;
  appearance: none;
  background-color: transparent;
  margin: 0 10px;
}

.seek-bar::-webkit-slider-thumb {
  appearance: none;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
}

.seek-bar::-moz-range-thumb {
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
}

.seek-bar:hover::-webkit-slider-thumb,
.seek-bar:hover::-moz-range-thumb {
  background-color: rgba(255, 255, 255, 0.8);
}

.time {
  font-size: 12px;
  color: white;
  margin-left: 10px;
}

.volume-controls {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.volume-button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 5px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.volume-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.volume-slider {
  width: 100px;
  height: 2px;
  appearance: none;
  background-color: white;
  margin-right: 10px;
}

.volume-slider::-webkit-slider-thumb {
  appearance: none;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
}

.volume-slider::-moz-range-thumb {
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
}

.volume-slider:hover::-webkit-slider-thumb,
.volume-slider:hover::-moz-range-thumb {
  background-color: rgba(255, 255, 255, 0.8);
}

.hide-controls .control-group,
.hide-controls .time {
  display: none;
}

/* Media Queries */
@media screen and (max-width: 767px) {
  /* Small screens */
  .audio-player {
    width: 100%; /* Take up the full width of the viewport */
  }
}

@media screen and (min-width: 768px) {
  /* Large screens */
  .audio-player {
    width: 100%; /* Let it adjust according to its parent */
    position: static; /* Remove the fixed positioning */
    transform: none;
  }
}
/* Additional Styles */
.play-pause {
  cursor: pointer;
  background-color: white;
  border: none;
  padding: 5px;
  color: #9c0faf;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  margin: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.play-pause:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.play-pause > svg {
  fill: #9c0faf;
}

.play-pause:hover > svg {
  fill: white;
}

/* Rotate animation */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.player-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  transition: transform 0.5s ease;
}

.rotate {
  animation: rotation 8s infinite linear;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.player-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
