.chat-window {
  width: 400px;
  margin: 20px auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow-y: scroll;
  padding: 10px;
  height: 500px;
}

.message-container {
  display: flex;
  flex-direction: column;
}

.message {
  background-color: #f1f0f0;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
  max-width: 70%;
}

.message.user {
  align-self: flex-end;
  background-color: #ffcacc;
}

.message.recipient {
  align-self: flex-start;
  background-color: #ffffff;
}

.message-timestamp {
  font-size: 10px;
  color: #777;
}

.input-container {
  display: flex;
  margin-top: 10px;
}

.input-container input {
  flex: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.input-container button {
  padding: 5px 10px;
  margin-left: 5px;
  background-color: #e91e63;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
